.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

:where(.css-dev-only-do-not-override-pr0fja).ant-tabs-top >.ant-tabs-nav::before, 
:where(.css-dev-only-do-not-override-pr0fja).ant-tabs-bottom >.ant-tabs-nav::before, 
:where(.css-dev-only-do-not-override-pr0fja).ant-tabs-top >div>.ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-pr0fja).ant-tabs-bottom >div>.ant-tabs-nav::before{
  border: 0;
}
.ant-tabs-top >.ant-tabs-nav::before, 
.ant-tabs-bottom >.ant-tabs-nav::before, 
.ant-tabs-top >div>.ant-tabs-nav::before,
.ant-tabs-bottom >div>.ant-tabs-nav::before{
  border: 0;
}
.modal {
  background-color: red !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.image-box {
  width: 100vw;
  /* تغيير العرض حسب احتياجك */
  height: 80vh;
  /* تغيير الارتفاع حسب احتياجك */
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  margin: -10px auto;
  /* توسيط الصندوق في الوسط */
  position: relative;
}

/* تستخدم لتحديد أنماط الصور داخل الصندوق (مثل الارتفاع والعرض) */
.image-box img {
  width: 100%;
  height: 100%;
  object-position: top;
  object-fit: cover;
}

@media (max-width: 575px) {
  .boxing-image {
    display: none !important;
  }
}

.ant-carousel .slick-slide img {
  object-fit: cover;
  object-position: top;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
  color: #fff;
}

.image-box Col img {
  object-fit: contain;
  object-position: top;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #9B7A39;
  text-decoration: underline;
  text-decoration-thickness: 400;
}

.ant-tabs .ant-tabs-tab:hover {
  color: #9B7A39;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
  color: #9B7A39;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
  margin-top: 15px;
  transform: translate(0px, 0px) !important;
}

.ant-carousel .slick-track {
  top: 10px;
}

.ant-btn-primary {
  background-color: #9B7A39;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #9B7A39;
}

.ant-carousel .slick-dots {
  bottom: 25px;
}

.ant-btn-primary {
  background-color: #9B7A39;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #9B7A39;
}

.ant-btn-primary {
  background-color: #9B7A39;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: #9B7A39;
  color: #9B7A39;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
  border-color: #9B7A39;
}