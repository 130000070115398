body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-tabs-tab-remove {
  display: none;
}

.ant-tabs-nav-add {
  display: none;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab {
  border: 0;
  color: #9B7A39;
  background-color: #ffffff00;
  font-weight: 400;

}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #9B7A39;
  font-weight: 600;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
  border: 0;
  color: #9B7A39;
  background-color: #ffffff00;
}

.nav-head {
  top: 0;
  left: 0;
  background-color: #fff;
  height: 120px;
  width: 100vw;
  position: fixed;
  z-index: 200;
}

.ant-tabs-nav {
  background-color: #ffffff00;
}

.first-subject {
  padding-right: 5%;
  padding-left: 5%;
}

.ant-form-item .ant-form-item-label>label {
  height: auto;
}

.ant-form-item .ant-form-item-label >label {
  display: inline-block;
}

.ant-form-item .ant-form-item-label {
  white-space: normal;
}

.ant-form-horizontal .ant-form-item-control {
  padding-right: 5px;
}

@media (max-width: 964px) {

  .ant-form-item-label:not([class*="ant-col-xs"]),
  .ant-form .ant-form-item .ant-form-item-control:not([class*="ant-col-xs"]) {
    text-align: center;
    flex: 0 0 100%;
  }
}
@media (max-width: 767px) {
.nav-head2{
  padding-top: 55px !important;
}
}

@media (max-width: 915px){
  button{
    margin-top: 0 !important;
  }
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  width: auto;
}

.slick-dots li {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 52%), 0 6px 20px 0 rgb(0 0 0 / 52%);
}

.ant-btn {
  padding: 2px 8px;
}

.boxing-image {
  object-fit: cover;
}

.ant-carousel .slick-slide .custom-button {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #952a2a;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 2em;
}

.ant-carousel .slick-slide h3 {
  word-wrap: break-word;
  bottom: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  left: calc(50% - 175px);
  position: absolute;
  text-align: center;
  width: 350px;
}

.stocks {
  color: #fff !important;
}

a {
  color:#2c230f;
  font-weight: 600;
}

a :hover{
  color:#2c230f8c;
}
:where(.css-dev-only-do-not-override-pr0fja) a:hover{
  color:#2c230f8c;
}

:where(.css-pr0fja) a:active{
  color: #9b7a39;
}
:where(.css-pr0fja).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible), :where(.css-pr0fja).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible), :where(.css-pr0fja).ant-tabs .ant-tabs-tab-btn:active, :where(.css-pr0fja).ant-tabs .ant-tabs-tab-remove:active{
  color: #9b7a39;
}
:where(.css-pr0fja) a:hover{
  color: #9b7a39;
}
.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab {
  font-size: 1.2em;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  text-underline-offset:8px;
}